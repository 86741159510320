import { useEffect, useRef, useState } from 'react'
import { useFormContext } from '@/context/use-form-context'
import TextField from '@mui/material/TextField'
import { Checkbox, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { CheckBox, CheckBoxOutlineBlank, InfoOutlined, LinkOutlined, Lock } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import { get } from 'lodash'
import { router } from '@inertiajs/react'
import axios from 'axios'
import { ToastCtl } from '@/utils/toast-ctl'

const InertiaRemoteAutocomplete = ({ name, input, url, callback, ...options }) => {
  const { data, setDataDebounce, overrideFieldValues, setOverrideFieldValues, errors, clearErrors } = useFormContext()
  const { multiple, disabled, tooltip, required, link, optionLabel } = input

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [inputValue, setInputValue] = useState('')

  let abortController = useRef()

  let value = get(data, name) || null
  if (multiple) {
    value = value ? value.map((item) => item.id || item) : []
  }

  const [fieldValue, setFieldValue] = useState(value)
  const selectedItems = useRef(options.defaultValue ? [options.defaultValue] : options.defaultValues || [])

  useEffect(() => {
    let override = get(overrideFieldValues, name)
    if (typeof override !== 'undefined') {
      if (multiple) {
        override = override ? override.map((item) => item.id || item) : []
      }

      setFieldValue(override)
      setOverrideFieldValues(name, undefined)
    }
  }, [get(overrideFieldValues, name)])

  const loadData = (initial = false) => {
    if (disabled) return

    if (abortController.current) abortController.current.abort()

    abortController.current = new AbortController()
    setLoading(true)
    setItems([])
    axios
      .get(url.replaceAll('%s', inputValue), { signal: abortController.current.signal })
      .then((response) => {
        const items = response.data['hydra:member'].map((item) => ({
          id: item.id,
          label: optionLabel ? optionLabel(item) : item.label || item.name
        }))
        if (options?.defaultItem) {
          // Instead of "please select" we use a custom default item
          items.unshift(options.defaultItem)
        }

        // Add the default value, if it's not part of the current search result
        if (initial && options.defaultValue && !items.find((item) => item.id === options.defaultValue.id)) {
          items.push(options.defaultValue)
        }

        // Add the default values, if they're not part of the current search result
        if (initial && options.defaultValues) {
          options.defaultValues.forEach((defaultValue) => {
            if (!items.find((item) => item.id === defaultValue.id)) {
              items.push(defaultValue)
            }
          })
        }

        // Make sure to populate items from the start
        if (!selectedItems.current.length) {
          selectedItems.current = items
        }

        setItems(items)
        setLoading(false)
      })
      .catch((e) => {
        if (axios.isCancel(e)) return

        setLoading(false)
        ToastCtl.show(e?.response?.data?.detail ?? e.message, 'error')
      })
  }

  useEffect(() => {
    if (typeof inputValue === 'undefined' || inputValue === fieldValue || parseInt(inputValue) === 0) {
      return
    }

    loadData()
  }, [inputValue, url])

  // Fetch dependent select on mount
  useEffect(() => {
    if (get(data, name)) {
      callback ? callback(get(data, name)) : null
    }

    loadData(true)
  }, [])

  return (
    <Autocomplete
      clearOnEscape
      autoComplete
      autoHighlight
      onOpen={() => {
        setInputValue('')
        loadData()
      }}
      multiple={multiple ?? false}
      disableCloseOnSelect={multiple ?? false}
      disabled={disabled ?? false}
      options={items}
      name={name}
      loading={loading}
      value={fieldValue}
      filterOptions={(x) => x}
      onInputChange={(e) => setInputValue(e?.target?.value)}
      getOptionLabel={(value) => {
        const parsedValue = value.hasOwnProperty('id') ? value?.id : value
        return selectedItems.current.filter((item) => item?.id == parsedValue)[0]?.label || value?.label || ''
      }}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === 'undefined') {
          return false
        }

        if (Array.isArray(value)) {
          return value.indexOf(option.id) > -1
        }

        const parsedValue = value.hasOwnProperty('id') ? value?.id : value
        return option.id == parsedValue
      }}
      onChange={(e, v) => {
        // MUI Multiselect doesn't provide an array if multiple=true and the selected options count == 0
        let values = multiple ? [v?.id] : v?.id
        if (Array.isArray(v)) {
          values = v.map((item) => item?.id || item)
        }

        if (typeof values === 'undefined') {
          values = null
        }

        if (values) {
          clearErrors(name)
        }

        selectedItems.current = [...selectedItems.current, ...items].filter((item) => values?.indexOf(item?.id) > -1)
        setFieldValue(values)
        setDataDebounce(name, values)
        callback ? callback(values) : null
      }}
      renderOption={(props, option, { selected }) =>
        multiple ? (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        ) : (
          <li {...props} key={`${name}-${option.id}`}>
            {option.label}
          </li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!get(errors, name)}
          helperText={get(errors, name)}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <IconButton>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                )}
                {disabled && (
                  <Tooltip title={'Bearbeitung deaktiviert'}>
                    <IconButton>
                      <Lock />
                    </IconButton>
                  </Tooltip>
                )}
                {link && (
                  <Tooltip title={'Zum Datensatz'}>
                    <IconButton onClick={() => router.get(link)}>
                      <LinkOutlined />
                    </IconButton>
                  </Tooltip>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
            style: input.innerstyle
          }}
          required={required}
          sx={{
            width: '100%',
            ...input.sx
          }}
          {...input}
        />
      )}
      {...options}
    />
  )
}
export default InertiaRemoteAutocomplete
