import { trans } from '@/utils/translation'
import { formatFloor, formatNumber } from '@/utils/format'
import ApartmentStateLabel from '../apartment-state-label'
import ApartmentState from '@/constants/ApartmentState'

export const COLUMNS = [
  {
    accessorKey: 'number',
    header: trans('field.number'),
    size: 100,
    id: 'number'
  },
  {
    accessorKey: 'label',
    header: trans('field.apartment.label'),
    size: 250,
    id: 'label'
  },
  {
    accessorKey: 'floor',
    header: trans('field.apartment.floor'),
    Cell: ({ renderedCellValue }) => formatFloor(renderedCellValue),
    size: 150,
    id: 'floor'
  },
  {
    accessorFn: (row) => row?.tenant?.user?.name,
    header: trans('field.apartment.tenant'),
    Cell: ({ renderedCellValue, row }) => (renderedCellValue ? renderedCellValue : trans('apartment.no_tenant')),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'setting.size',
    header: trans('field.apartment.size'),
    Cell: ({ renderedCellValue, row }) => (renderedCellValue ? `${formatNumber(renderedCellValue)} m²` : '-'),
    size: 150,
    id: 'size'
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    Cell: ({ renderedCellValue }) => <ApartmentStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const TENANT_COLUMNS = [
  {
    accessorKey: 'number',
    header: trans('field.number'),
    size: 100,
    enableSorting: false
  },
  {
    accessorFn: (row) => `${row.address.street} ${row.address.streetNumber}`,
    header: trans('field.street'),
    size: 250,
    enableSorting: false
  },
  {
    accessorKey: 'label',
    header: trans('field.apartment.label'),
    size: 250,
    enableSorting: false
  },
  {
    accessorKey: 'floor',
    header: trans('field.apartment.floor'),
    Cell: ({ renderedCellValue }) => formatFloor(renderedCellValue),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'setting.size',
    header: trans('field.apartment.size'),
    Cell: ({ renderedCellValue, row }) => (renderedCellValue ? `${formatNumber(renderedCellValue)} m²` : '-'),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    Cell: ({ renderedCellValue }) => <ApartmentStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.apartment.ALL') },
  [ApartmentState.DONE]: {
    color: 'success',
    label: trans('state.apartment.DONE')
  },
  [ApartmentState.OPEN]: {
    color: 'warning',
    label: trans('state.apartment.OPEN')
  },
  [ApartmentState.ARCHIVED]: {
    color: 'primary',
    label: trans('state.apartment.ARCHIVED')
  }
}
