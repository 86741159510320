import { DialogActions, DialogTitle } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import { useModalContext } from '@/context/use-modal-context'
import CustomButton from '@/components/custom-button'
import Typography from '@mui/material/Typography'

export default function BillingResetModal({ callback }) {
  const { closeModal } = useModalContext()

  return (
    <>
      <DialogTitle>{trans('general.attention')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <Typography variant={'body2'}>{trans('billing.detail.submit.reset_hint')}</Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant={'outlined'}
          onClick={() => {
            closeModal('billingReset')
          }}
        >
          {trans('button.cancel')}
        </CustomButton>
        <CustomButton
          variant={'contained'}
          onClick={() => {
            callback()
            closeModal('billingReset')
          }}
        >
          {trans('button.confirm')}
        </CustomButton>
      </DialogActions>
    </>
  )
}
