import { useEffect, useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import { Alert, Stack } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import Iconify from '@/components/iconify'
import { router, usePage } from '@inertiajs/react'
import IconButton from '@mui/material/IconButton'
import { formatCurrency, formatDate, formatNumber } from '@/utils/format'
import useSingletonAxios from '@/hooks/use-singleton-axios'
import { useModalContext } from '@/context/use-modal-context'
import BillingFuelCostModal from '@/sections/billing/billing-fuel-cost-modal'
import axios from 'axios'
import { ToastCtl } from '@/utils/toast-ctl'
import CollapseCard from '@/components/collapse-card'
import FuelCostDeliveryTypes from '@/constants/FuelCostDeliveryTypes'
import OverlayCard from '@/sections/billing/components/overlay-card'

export default function BillingFuelCostTable({ expanded, onExpand, editForbidden }) {
  const { billing } = usePage().props
  const { openModal } = useModalContext()
  const [calculatedFuelCost, setCalculatedFuelCost] = useState(0)

  const request = useSingletonAxios()

  useEffect(() => {
    if (billing.fuelCosts?.length > 0) {
      request({
        method: 'POST',
        url: `/billings/${billing.id}/fuel-costs`
      })
        .then((response) => {
          setCalculatedFuelCost(response.data.calculatedCosts?.calculatedUsageCostGross)
        })
        .catch((e) => {
          console.log(e)
          ToastCtl.show(trans('billing.detail.fuel_cost.modal.calculation_failed'), 'error')
        })
    }
  }, [billing.fuelCosts])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fuelCostType.label',
        header: trans('billing.detail.fuel_cost.columns.description'),
        size: 100,
        enableSorting: false
      },
      {
        accessorKey: 'deliveryType',
        header: trans('billing.detail.fuel_cost.columns.type'),
        Cell: ({ row, renderedCellValue }) =>
          trans('billing.detail.fuel_cost.modal.' + (renderedCellValue || row.original.fuelCosts?.deliveryType)),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => formatDate(row.date),
        header: trans('billing.detail.fuel_cost.columns.date'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => `${formatNumber(row.amount)} ${row.fuelCostType.measurementUnit}`,
        header: trans('billing.detail.fuel_cost.columns.amount'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => formatCurrency(row.grossAmount),
        header: trans('billing.detail.fuel_cost.columns.brutto'),
        size: 100,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <></>,
    columns,
    data: billing.fuelCosts,
    enableRowActions: !editForbidden,
    renderRowActions: ({ row }) => (
      <Stack direction={{ md: 'row', sm: 'column' }} spacing={1}>
        <IconButton
          key={'editFuelCost'}
          onClick={() =>
            openModal(
              'billingFuelCostModal',
              <BillingFuelCostModal billingId={billing.id} billingFuelCost={row.original} />
            )
          }
        >
          <Iconify icon="solar:pen-bold" />
        </IconButton>
        <IconButton
          key={'delete'}
          sx={{ color: 'error.main' }}
          onClick={() => {
            axios
              .delete(`/api/billing_fuel_cost/${row.original.id}`)
              .then(() => {
                router.reload()
              })
              .catch((e) => ToastCtl.show(e.message, 'error'))
          }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Stack>
    )
  })

  const validFuelCosts =
    billing.fuelCosts.some((item) => item.deliveryType === FuelCostDeliveryTypes.INITIAL) &&
    billing.fuelCosts.some((item) => item.deliveryType === FuelCostDeliveryTypes.FINAL)

  return (
    <>
      <OverlayCard>
        <CollapseCard
          title={trans('billing.detail.fuel_cost.title')}
          header={
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Typography variant="body2"> {formatCurrency(calculatedFuelCost)} </Typography>
              <CustomButton
                variant="contained"
                color="primary"
                disabled={editForbidden}
                onClick={() => openModal('billingFuelCostModal', <BillingFuelCostModal billingId={billing.id} />)}
              >
                + {trans('billing.detail.fuel_cost.add')}
              </CustomButton>
            </Stack>
          }
          expand={expanded}
          onExpand={onExpand}
        >
          {billing.property.heatingSettings?.fuelCostType?.storageable && !validFuelCosts && (
            <Alert sx={{ borderRadius: 0 }} severity={'error'}>
              {trans('billing.detail.fuel_cost.invalid')}
            </Alert>
          )}
          <MaterialReactTable table={table} />
        </CollapseCard>
      </OverlayCard>
    </>
  )
}
