import Button from '@mui/material/Button'

const CustomButton = ({ children, ...options }) => {
  return (
    <Button variant={'outlined'} {...options}>
      {children}
    </Button>
  )
}

export default CustomButton
