import Label from '../../components/label'
import { memo } from 'react'
import { ERROR_STATUS_OPTIONS } from '@/sections/device/table/device-table-definitions'
import { Stack } from '@mui/material'

function DeviceErrorStateLabel({ state }) {
  const colorVariant = ERROR_STATUS_OPTIONS[state]?.color || 'default'
  const translation = ERROR_STATUS_OPTIONS[state]?.label

  return (
    <Stack sx={{background: '#fff', borderRadius: '6px', display: 'inherit'}}>
      <Label variant="soft" color={colorVariant} sx={{ textTransform: 'none' }}>
        {translation}
      </Label>
    </Stack>
  )
}

export default memo(DeviceErrorStateLabel)
