export default Object.freeze({
  OIL: 'OIL',
  PELLET: 'PELLET',
  HEAT_PUMP: 'HEAT_PUMP',
  REMOTE: 'REMOTE',
  COKE: 'COKE',
  NATURAL_GAS: 'NATURAL_GAS',
  OIL_CALORIFIC_ACTIVE: 'OIL_CALORIFIC_ACTIVE',
  GEOTHERMAL_HEAT: 'GEOTHERMAL_HEAT',
  LIQUID_GAS: 'LIQUID_GAS',
  BROWN_COAL: 'BROWN_COAL',
  BIOGAS: 'BIOGAS',
  WOOD_CHIPS: 'WOOD_CHIPS',
  HARD_COAL: 'HARD_COAL',
  NATURAL_GAS_CALORIFIC_ACTIVE: 'NATURAL_GAS_CALORIFIC_ACTIVE',
  LIQUID_GAS_CALORIFIC_ACTIVE: 'LIQUID_GAS_CALORIFIC_ACTIVE',
  BIOGAS_CALORIFIC_ACTIVE: 'BIOGAS_CALORIFIC_ACTIVE',
  HYBRID_HEATING: 'HYBRID_HEATING',
  WOOD: 'WOOD'
})
