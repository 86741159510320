import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Stack } from '@mui/material'
import { COLUMNS } from './table/log-table-definitions'
import PropTypes from 'prop-types'
import JsonFormatter from 'react-json-formatter'

export default function LogListTable({ defaultFilter = {}, defaultSorting = [] }) {
  const path = `/logs/list`

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['number'], defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    setRefresh
  } = methods

  const jsonStyle = {
    propertyStyle: { color: '#0a0f5d' },
    stringStyle: { color: '#2e3129' },
    numberStyle: { color: '#d35a30' }
  }

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <></>,
    renderDetailPanel: ({ row }) => (
      <Stack>
        <JsonFormatter json={row.original.context} jsonStyle={jsonStyle} tabWith={4} />
        <JsonFormatter json={row.original.extra} jsonStyle={jsonStyle} tabWith={4} />
      </Stack>
    ),
    muiTableBodyRowProps: ({ row, table }) => ({
      onClick: () => row.toggleExpanded(!row.getIsExpanded()),
      sx: { cursor: 'pointer' }
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}

LogListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array
}
