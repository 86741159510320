import { useForm } from '@/hooks/use-form'
import { DialogActions, DialogTitle, Stack } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { FormProvider } from '@/context/use-form-context'
import { ToastCtl } from '@/utils/toast-ctl'
import { router } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import CustomButton from '@/components/custom-button'
import { useModalContext } from '@/context/use-modal-context'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import InertiaCheckbox from '@/components/form/inertia-checkbox'
import CostGroup from '@/constants/CostGroup'
import InertiaTextfield from '@/components/form/inertia-textfield'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'

export default function BillingHeatingCostModal({ billingId, billingHeatingCost }) {
  const { closeModal } = useModalContext()
  const ready = useRef(false)

  const methods = useForm({
    url: {
      method: billingHeatingCost?.id ? 'PUT' : 'POST',
      location: billingHeatingCost?.id
        ? `/api/billing_heating_cost/${billingHeatingCost.id}`
        : `/api/billing_heating_cost/create`
    },
    defaultValues: {
      heatingCostType: billingHeatingCost?.heatingCostType?.id ?? null,
      heatingCostGroup: billingHeatingCost?.heatingCostType?.costGroup ?? 'ALL',
      date: billingHeatingCost?.date,
      taxRate: billingHeatingCost?.taxRate || 19,
      grossAmount: billingHeatingCost?.grossAmount,
      netAmount: billingHeatingCost?.netAmount,
      isWork: billingHeatingCost?.isWork,
      workAmount: billingHeatingCost?.workAmount || 0,
      alternativeCost: billingHeatingCost?.alternativeCost,
      billing: billingId
    }
  })
  const { submit, submitting, data, setData, setOverrideFieldValues } = methods

  const costGroup = [
    { id: 'ALL', label: 'Alle' },
    ...Object.values(CostGroup).map((type) => ({
      id: type,
      label: trans(`billing.detail.heating_cost.modal.${type}`)
    }))
  ]

  const [costTypes, setCostTypes] = useState([])
  useEffect(() => {
    axios
      .get('/api/heating_cost_types?itemsPerPage=200')
      .then((response) => {
        setCostTypes(response.data['hydra:member'])
        setGroupCostTypes(response.data['hydra:member'])

        ready.current = true
      })
      .catch((e) => ToastCtl.show(e.message, 'error'))
  }, [])

  const [groupCostTypes, setGroupCostTypes] = useState([])

  const calculateBrutto = (netto, taxRate) => {
    if (taxRate < 0 || !netto) return

    setData('grossAmount', netto * (1 + taxRate / 100))
    setOverrideFieldValues({ ['grossAmount']: netto * (1 + taxRate / 100) })
  }

  const calculateNetto = (brutto, taxRate) => {
    if (taxRate < 0 || !brutto) return

    setData('netAmount', brutto / (1 + taxRate / 100))
    setOverrideFieldValues({ ['netAmount']: brutto / (1 + taxRate / 100) })
  }

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('billingHeatingCost')
    })
  }
  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle> Heiznebenkosten </DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaAutocomplete
                name={'heatingCostGroup'}
                input={{
                  label: trans('billing.detail.heating_cost.modal.group')
                }}
                items={costGroup}
                callback={(value) => {
                  if (value !== null && ready.current) {
                    // Prevent submit on page load
                    setData('heatingCostType', null)
                    setOverrideFieldValues({ heatingCostType: null })
                  }

                  if (value !== 'ALL') {
                    setGroupCostTypes(costTypes.filter((item) => item.costGroup === value))
                  } else {
                    setGroupCostTypes(costTypes)
                  }
                }}
              />

              <InertiaAutocomplete
                name={'heatingCostType'}
                input={{
                  label: trans('billing.detail.heating_cost.modal.type')
                }}
                items={groupCostTypes}
              />

              <InertiaTextfield
                name={'alternativeCost'}
                input={{
                  label: trans('billing.detail.heating_cost.modal.alternative_cost')
                }}
              />
              <InertiaDatePicker
                name={'date'}
                input={{
                  label: trans('billing.detail.heating_cost.modal.date')
                }}
              />
              <InertiaAutocomplete
                name={'taxRate'}
                input={{ label: trans('billing.detail.heating_cost.modal.tax_rate'), suffix: ' %' }}
                items={[
                  { id: 0, label: '0 %' },
                  { id: 7, label: '7 %' },
                  { id: 19, label: '19 %' },
                  { id: -1, label: 'Andere MwSt. Satz' }
                ]}
                callback={(value) => {
                  if (!data.netAmount) {
                    calculateNetto(data.grossAmount, value)
                  }
                  calculateBrutto(data.netAmount, value)
                }}
              />
              <InertiaNumericField
                name={'grossAmount'}
                input={{
                  label: trans('billing.detail.heating_cost.modal.brutto'),
                  fixedDecimalScale: true,
                  allowNegative: true
                }}
                callback={(value) => calculateNetto(value, data.taxRate)}
              />
              <InertiaNumericField
                name={'netAmount'}
                input={{
                  label: trans('billing.detail.heating_cost.modal.netto'),
                  fixedDecimalScale: true,
                  allowNegative: true
                }}
                callback={(value) => calculateBrutto(value, data.taxRate)}
              />

              <InertiaCheckbox name={'isWork'} input={{ label: trans('billing.detail.heating_cost.modal.work') }} />
              {data.isWork && (
                <InertiaNumericField
                  name={'workAmount'}
                  input={{
                    label: trans('billing.detail.heating_cost.modal.work_amount'),
                    fixedDecimalScale: true
                  }}
                />
              )}
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('billingHeatingCost')}> {trans('button.cancel')} </CustomButton>
        <LoadingButton loading={submitting} type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          {trans('button.confirm')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}
