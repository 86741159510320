import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'

import Typography from '@mui/material/Typography'
import { usePage } from '@inertiajs/react'
import { formatDate } from '@/utils/format'
import CustomLabelValue from '../../components/custom-label-value'
import { useModalContext } from '@/context/use-modal-context'
import PropertyEditHeatingSettingsModal from '@/sections/property/property-edit-heating-settings-modal'

export default function PropertyHeatingSettings() {
  const { property } = usePage().props
  const { openModal } = useModalContext()

  return (
    <>
      <Card>
        <CardHeader
          title={trans('property.heating.title')}
          action={
            <IconButton
              onClick={() =>
                openModal('propertyEditHeatingSettings', <PropertyEditHeatingSettingsModal property={property} />)
              }
            >
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          }
        />
        <Stack direction={'column'} spacing={1} sx={{ p: 3, typography: 'body2' }}>
          <Typography variant={'subtitle2'}> {trans('property.heating.basic_data.title')}</Typography>
          <CustomLabelValue
            label={trans('property.heating.basic_data.type')}
            value={trans(`heating_system.${property.heatingSettings.heatingSystem.toUpperCase()}`)}
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.install_date')}
            value={formatDate(property.heatingSettings.begin, 'MM.YYYY')}
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.removal_date')}
            value={property.heatingSettings.end ? formatDate(property.heatingSettings.end, 'MM.YYYY') : '-'}
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.construction_year')}
            value={
              property.heatingSettings.constructionYear
                ? formatDate(property.heatingSettings.constructionYear, 'YYYY')
                : 'n.b.'
            }
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.fuel')}
            value={`${property.heatingSettings.fuelCostType.label}`}
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.efficiency')}
            value={`${property.heatingSettings.efficiency} %`}
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.is_bhkw')}
            value={property.heatingSettings.isCogenerationPlant ? trans('general.yes') : trans('general.no')}
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.bhkw_power')}
            value={
              property.heatingSettings.cogenerationPlantCredit
                ? `${property.heatingSettings.cogenerationPlantCredit} %`
                : '-'
            }
          />
          <CustomLabelValue
            label={trans('property.heating.basic_data.central_treatment')}
            value={property.heatingSettings.isCentralWarmWater ? trans('general.yes') : trans('general.no')}
          />

          <Typography sx={{ mt: 2 }} variant={'subtitle2'}>
            {' '}
            {trans('property.heating.temperature.title')}
          </Typography>
          <CustomLabelValue
            label={trans('property.heating.temperature.warm_water')}
            value={`${property.heatingSettings.warmWaterTemperature} °C`}
          />
          <CustomLabelValue
            label={trans('property.heating.temperature.supply')}
            value={`${property.heatingSettings.supplyTemperature} °C`}
          />
          <CustomLabelValue
            label={trans('property.heating.temperature.return')}
            value={`${property.heatingSettings.returnTemperature} °C`}
          />
          <CustomLabelValue
            label={trans('property.heating.temperature.room')}
            value={`${property.heatingSettings.roomTemperature} °C`}
          />
        </Stack>
      </Card>
    </>
  )
}
