import { createContext, useContext } from 'react'

const HookFormContext = createContext(null)

export const useFormContext = () => useContext(HookFormContext)

export const FormProvider = (props) => {
  const { children, ...data } = props
  return <HookFormContext.Provider value={data}>{children}</HookFormContext.Provider>
}
