import axios from 'axios'

export const log = async (message, context, type = 'error') => {
  console.error(message, context, type)
  //navigator.sendBeacon('/logging', { type, message, context: context })
  await axios.post(
    '/logging',
    { type, message, context: context },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}
