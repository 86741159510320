// Apex charts stops the initial animation if an interaction is triggered while the animation is still running
// So we wait a bit before toggling the series and fake the disabled legend state
import dayjs from 'dayjs'

export const disableSeries = (chartRef, seriesName) => {
  const legend = document.querySelector(`span[data\\:default-text="${seriesName}"]`)
  legend?.parentElement?.querySelector('.apexcharts-legend-marker')?.classList?.add('apexcharts-inactive-legend')
  legend?.parentElement?.classList.add('apexcharts-inactive-legend')

  setTimeout(() => {
    chartRef?.current?.chart?.toggleSeries(seriesName)
  }, 1000)
}

export const filterValuesByYear = (usage, year) => {
  return Object.entries(usage)
    .filter(([key, value]) => parseInt(dayjs(key).format('YYYY')) === parseInt(year))
    .map(([key, value]) => value)
}

export const sumValuesByYear = (usage, year) => {
  return Object.entries(usage)
    .filter(([key, value]) => parseInt(dayjs(key).format('YYYY')) === parseInt(year))
    .reduce((acc, [key, value]) => acc + value, 0)
}

export const normalizeValues = (value1, value2) => {
  const maxValue = Math.max(value1, value2)
  if (maxValue === 0) {
    return [1, 1] // Show a little bit of a progress bar
  }

  const normalizedValue1 = (value1 / maxValue) * 100
  const normalizedValue2 = (value2 / maxValue) * 100

  return [normalizedValue1, normalizedValue2]
}
