import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import { Card, CardHeader, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import CustomLabelValue from '../../components/custom-label-value'
import UserState from '@/constants/UserState'
import UserEditModal from '@/sections/user/user-edit-modal'
import { useModalContext } from '@/context/use-modal-context'

export default function UserData() {
  const { user } = usePage().props
  const { openModal } = useModalContext()

  return (
    <>
      <Card>
        <CardHeader
          title={trans('user.data.title')}
          action={
            <IconButton onClick={() => openModal('userDataEdit', <UserEditModal user={user} />)}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          }
        />
        <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
          <Typography variant="body2">{user.name}</Typography>
          <Typography variant="body2">
            {user.address.street} {user.address.streetNumber}
          </Typography>
          <Typography variant="body2">
            {user.address.postcode} {user.address.city}
          </Typography>
          <Typography variant="body2">{user.address.email}</Typography>
          <Typography variant="body2">{user.address.phone}</Typography>
          <CustomLabelValue
            label={trans('user.data.assetapp_access')}
            value={
              user.state === UserState.ACTIVE || user.state === UserState.INVITED
                ? trans('general.yes')
                : trans('general.no')
            }
          />
        </Stack>
      </Card>
    </>
  )
}
