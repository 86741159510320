import { trans } from '@/utils/translation'
import UserState from '../../../constants/UserState'
import UserStateLabel from '@/sections/user/user-state-label'

export const COLUMNS = [
  {
    accessorKey: 'identifier',
    header: trans('field.number'),
    size: 100,
    enableSorting: false
  },
  {
    accessorKey: 'name',
    header: trans('field.name'),
    size: 250
  },
  {
    accessorFn: (row) => row.email ?? '-',
    header: trans('field.email'),
    size: 150
  },
  {
    accessorKey: 'roles',
    header: trans('field.role'),
    Cell: ({ renderedCellValue }) => renderedCellValue.map((item) => trans(`role.${item}`)).join(', '),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    Cell: ({ renderedCellValue }) => <UserStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.user.ALL') },
  [UserState.ACTIVE]: { color: 'success', label: trans('state.user.ACTIVE') },
  [UserState.INVITED]: {
    color: 'warning',
    label: trans('state.user.INVITED')
  },
  [UserState.INACTIVE]: {
    color: 'primary',
    label: trans('state.user.INACTIVE')
  },
  [UserState.DISABLED]: {
    color: 'error',
    label: trans('state.user.DISABLED')
  }
}
