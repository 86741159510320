import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Iconify from '@/components/iconify'

export default function BillingDetailResult() {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'label',
        header: trans('billing.detail.result.columns.description'),
        size: 100,
        enableSorting: false
      },
      {
        accessorKey: 'date',
        header: trans('billing.detail.result.columns.date'),
        size: 100,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('billing.detail.result.header')} </Typography>
      </Stack>
    ),
    columns,
    data: [],
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="editApartment">
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
      </MenuItem>,
      <MenuItem key="delete" sx={{ color: 'error.main' }}>
        <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} />
      </MenuItem>
    ]
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
