import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import { useBoolean } from '@/hooks/use-boolean'
import ThemeProvider from '@/theme'
import Main from "@/layouts/dashboard/main";
import HeaderSimple from "@/layouts/header-simple/header-simple";

// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
  const nav = useBoolean()

  return (
    <>
      <ThemeProvider>
        <HeaderSimple />
        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' }
          }}
        >
          <Main>{children}</Main>
        </Box>
      </ThemeProvider>
    </>
  )
}

SimpleLayout.propTypes = {
  children: PropTypes.node
}
