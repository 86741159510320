import { trans } from '@/utils/translation'
import { formatDate, formatDeviceLocation, formatDeviceValue } from '@/utils/format'
import DeviceState from '../../../constants/DeviceState'
import DeviceStateLabel from '../device-state-label'
import DeviceDataErrorState from '@/constants/DeviceDataErrorState'

export const COLUMNS = [
  {
    accessorKey: 'number',
    header: trans('field.number'),
    size: 100,
    id: 'number'
  },
  {
    accessorKey: 'type',
    header: trans('field.device.type'),
    Cell: ({ renderedCellValue }) => trans(`device.type.${renderedCellValue?.toUpperCase()}`),
    size: 250,
    id: 'type'
  },
  {
    accessorKey: 'location',
    header: trans('field.device.location'),
    Cell: ({ row }) => formatDeviceLocation(row.original),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'latestDeviceValue.reportDate',
    header: trans('field.device.last_report_date'),
    Cell: ({ renderedCellValue }) =>
      renderedCellValue ? formatDate(renderedCellValue) : trans('device.not_received_yet'),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'latestDeviceValue.value',
    header: trans('field.device.value'),
    Cell: ({ renderedCellValue, row }) =>
      formatDeviceValue(renderedCellValue, row.original.type, row.original.latestDeviceValue?.unit),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    Cell: ({ renderedCellValue }) => <DeviceStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.device.ALL') },
  [DeviceState.ACTIVE]: {
    color: 'success',
    label: trans('state.device.ACTIVE')
  },
  [DeviceState.HINT]: {
    color: 'warning',
    label: trans('state.device.HINT')
  },
  [DeviceState.DISMANTLED]: {
    color: 'primary',
    label: trans('state.device.DISMANTLED')
  },
  [DeviceState.ERROR]: { color: 'error', label: trans('state.device.ERROR') }
}

export const ERROR_STATUS_OPTIONS = {
  [DeviceDataErrorState.RESOLVED]: {
    color: 'success',
    label: trans('state.device_error.RESOLVED')
  },
  [DeviceDataErrorState.OPEN]: {
    color: 'warning',
    label: trans('state.device_error.OPEN')
  },
  [DeviceDataErrorState.INACTIVE]: {
    color: 'default',
    label: trans('state.device_error.INACTIVE')
  },
  [DeviceDataErrorState.IGNORED]: {
    color: 'primary',
    label: trans('state.device_error.IGNORED')
  }
}
