import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import { useCallback, useState } from 'react'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { usePage } from '@inertiajs/react'
import { bgGradient } from '@/theme/css'
import { alpha, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import BillingDetailTransmission from '@/sections/billing/billing-detail-transmission'
import BillingStateLabel from '@/sections/billing/billing-state-label'
import { formatDate } from '@/utils/format'
import InfoView from '@/components/info-view'
import BillingDetailMessages from '@/sections/billing/billing-detail-messages'
import BillingDetailResult from '@/sections/billing/billing-detail-result'
import VideoModal from '@/components/modal/video-modal'
import { useModalContext } from '@/context/use-modal-context'

const TABS = [
  {
    value: 'transmission',
    label: trans('billing.detail.tabs.transmission')
  },
  /*{
    value: 'message',
    label: trans('billing.detail.tabs.message')
  },*/
  {
    value: 'result',
    label: trans('billing.detail.tabs.result')
  }
]

export default function BillingDetailView() {
  const { billing } = usePage().props
  const { openModal } = useModalContext()

  const theme = useTheme()
  const property = billing.property

  const [currentTab, setCurrentTab] = useState('transmission')
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        {trans('billing.detail.header')}
      </Typography>

      <Stack direction={'row'} spacing={2} flexWrap={'wrap'}>
        <Card
          sx={{
            mb: 3,
            height: 290,
            minWidth: { md: 650 },
            flexGrow: 10
          }}
        >
          <Box
            sx={{
              height: 1,
              color: 'common.white',
              ...bgGradient({
                direction: 'to right',
                color: alpha(theme.palette.primary.lighter, 0.1),
                imgUrl: require('../../../../images/Cover/Liegenschaft.jpg'),
                startColor: alpha(theme.palette.primary.darker, 0.4)
              })
            }}
          >
            <Stack direction={'column'} justifyContent={'space-between'} height={'80%'}>
              <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'end'} justifyContent={'space-between'} p={4}>
                <div></div>
                <BillingStateLabel variant={'filled'} state={billing.state} />
              </Stack>
              <Stack direction={'column'} px={4} sx={{ mb: { md: 2, sm: 6, xs: 8 } }}>
                <Typography variant={'h6'}>
                  {property?.address?.street} {property?.address?.number}
                </Typography>
                <Typography variant={'body2'} mb={2}>
                  {property?.address?.zip} {property?.address?.city}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    color: 'text.primary',
                    backgroundColor: 'background.paper',
                    borderRadius: '8px',
                    width: 'fit-content',
                    mt: { sm: 0 },
                    p: 2,
                    position: { xl: 'absolute', lg: 'initial' },
                    bottom: { sm: '20px' },
                    boxShadow: '0px 0px 15px #00000029',
                    zIndex: { sm: 55 }
                  }}
                >
                  {formatDate(billing?.begin)} - {formatDate(billing?.end)}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  xs: 'center',
                  md: 'flex-end'
                }
              }
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
        </Card>
        <Card
          sx={{
            mb: 3,
            maxHeight: 290,
            flexGrow: 1
          }}
        >
          <InfoView
            header={trans('billing.detail.info.header')}
            description={trans('dashboard.info.description')}
            tip={trans('dashboard.info.tip')}
            button={trans('dashboard.info.button')}
            sx={{
              flex: 1
            }}
            onClick={() =>
              openModal('videoModal', <VideoModal src={'09_Uebermittlung.mp4'} controls={true} autoPlay={true} />, {
                maxWidth: 'lg'
              })
            }
          />
        </Card>
      </Stack>

      {currentTab === 'transmission' && <BillingDetailTransmission />}
      {currentTab === 'message' && <BillingDetailMessages />}
      {currentTab === 'result' && <BillingDetailResult />}
    </>
  )
}
