import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { Card, Stack, Typography } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { usePage } from '@inertiajs/react'
import { formatDate } from '@/utils/format'
import DeviceErrorStateLabel from '@/sections/device/device-error-state-label'

export default function DeviceDataErrorTable() {
  const { device } = usePage().props

  const columns = useMemo(
    () => [
      {
        accessorKey: 'reportDate',
        header: trans('device.error.updated_at'),
        Cell: ({ row, renderedCellValue }) =>
          renderedCellValue ? formatDate(renderedCellValue) : formatDate(row.original.createdAt),

        size: 100,
        enableSorting: false
      },
      {
        accessorKey: 'type',
        header: trans('device.error.type'),
        Cell: ({ renderedCellValue }) => trans(`device.error_type.${renderedCellValue}`),
        size: 300,
        enableSorting: false
      },
      {
        accessorKey: 'state',
        header: trans('field.state'),
        Cell: ({ renderedCellValue }) => <DeviceErrorStateLabel state={renderedCellValue} />,
        size: 150,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('device.error.header')} </Typography>
      </Stack>
    ),
    columns,
    data: device.deviceDataErrors,
    enableRowActions: false
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
