import { formatDate } from '@/utils/format'
import Level from '@/constants/Level'

const ERROR_MAP = {
  'REQUEST FAILED:': 'API',
  'FRONTEND ERROR:': 'Frontend',
  'EXCEPTION:': 'Backend'
}

export const COLUMNS = [
  {
    accessorFn: (row) => {
      const reg = new RegExp(Object.keys(ERROR_MAP).join('|'), 'g')
      const match = row.message.match(reg)
      return match && ERROR_MAP[match] ? ERROR_MAP[match] : 'Allgemeiner Fehler'
    },
    header: 'Typ',
    size: 100,
    enableSorting: false
  },
  {
    accessorFn: (row) => {
      const reg = new RegExp(Object.keys(ERROR_MAP).join('|'), 'g')
      return row.message.replace(reg, (matched) => '')
    },
    header: 'Nachricht',
    size: 350,
    enableSorting: false
  },
  {
    accessorFn: (row) => Object.entries(Level).find(([key, value]) => value === row.level)?.[0],
    header: 'Level',
    enableSorting: false
  },
  {
    accessorFn: (row) => formatDate(row.createdAt, 'DD.MM.YYYY HH:mm:ss'),
    header: 'Datum',
    enableSorting: false
  }
]
