import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import { usePage } from '@inertiajs/react'
import { formatDate, formatNumber } from '@/utils/format'
import DeviceTypes from '@/constants/DeviceTypes'
import DeviceCreateEditModal from '@/sections/device/device-create-edit-modal'
import { useModalContext } from '@/context/use-modal-context'

export default function DeviceData() {
  const { device, auth } = usePage().props
  const { openModal } = useModalContext()

  return (
    <Card>
      <CardHeader
        title={trans('device.data.title')}
        action={
          device.isManual ? (
            <IconButton onClick={() => openModal('deviceCreate', <DeviceCreateEditModal device={device} />)}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          ) : null
        }
      />

      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Typography variant="subtitle2">{trans('device.data.basic_data.title')}</Typography>

        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.counter_number')}</Box>
          {device.number}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.type')}</Box>
          {trans(`device.type.${device.type}`)}
        </Stack>
        {device.type === DeviceTypes.HCA && (
          <Stack direction="row" justifyContent="space-between">
            <Box component="span">{trans('device.data.basic_data.factor')}</Box>
            {formatNumber(device.factor, 3)}
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.installed_date')}</Box>
          {formatDate(device.begin)}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.removal_date')}</Box>
          {device.end ? formatDate(device.end) : '-'}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.manufacturer')}</Box>
          {device.manufacturer}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.counter_type')}</Box>
          {trans(`device.storage_type.${device.storageType}`)}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.deadline')}</Box>
          {formatDate(device.reportDate, 'DD.MM')}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('field.device.gateway')}</Box>
          {device.deviceGateways
            ?.slice(0, 4)
            .map((deviceGateway) => deviceGateway.gateway.identifier)
            .join(', ') + (device.deviceGateways?.length > 4 ? '...' : '') ?? '-'}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('field.device.auto_gateway')}</Box>
          {device.isAutoGateway ? trans('general.yes') : trans('general.no')}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box component="span">{trans('device.data.basic_data.manual_counter')}</Box>
          {device.isManual ? trans('general.yes') : trans('general.no')}
        </Stack>
      </Stack>
    </Card>
  )
}
