import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Chart, { useChart } from '@/components/chart'
import Iconify from '@/components/iconify'
import Tooltip from '@mui/material/Tooltip'
import { trans } from '@/utils/translation'
import { getDecimalByDeviceType, getUnitByDeviceType } from '@/utils/format'
import DeviceTypes from '@/constants/DeviceTypes'
import dayjs from 'dayjs'
import { filterValuesByYear } from '@/utils/chart'
import EmptyItem from '@/sections/dashboard-overview/components/empty-item'
import Box from '@mui/material/Box'

export default function DashboardYearConsumption({
  title,
  subheader,
  warmWaterEnergyUsage,
  heatUsage,
  heatingSettings,
  ...other
}) {
  const chartRef = useRef()

  const header = (
    <>
      {title}
      <Tooltip title={trans('dashboard.consumption.declaration')}>
        <Iconify icon={'bi:info-circle'} sx={{ ml: 1, width: '14px', height: '14px' }} />
      </Tooltip>
    </>
  )

  const interpolateData = (data) => {
    return data.map((value, index, arr) => {
      if (value === null) {
        let prevValue = null
        for (let i = index - 1; i >= 0; i--) {
          if (arr[i] !== null) {
            prevValue = arr[i]
            break
          }
        }
        let nextValue = null
        for (let i = index + 1; i < arr.length; i++) {
          if (arr[i] !== null) {
            nextValue = arr[i]
            break
          }
        }
        if (prevValue && nextValue) {
          return (prevValue + nextValue) / 2
        }

        return 0
      }
      return value
    })
  }

  const chart = {
    legend: {
      position: 'top',
      horizontalAlign: 'right'
    },
    grid: {
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    tooltip: {
      y: {
        formatter: (value, config) => {
          if (value === null || value === undefined) {
            return trans('dashboard.consumption.data_empty')
          }

          return `${value.toLocaleString('de-DE', { maximumFractionDigits: getDecimalByDeviceType(DeviceTypes.HEAT) })} ${getUnitByDeviceType(DeviceTypes.HEAT)}`
        }
      }
    },
    yaxis: {
      labels: {
        offsetX: -20,

        formatter: (value) => {
          if (!value) {
            return 0
          }

          return `${value.toFixed(getDecimalByDeviceType(DeviceTypes.HEAT))}`
        }
      },
      title: {
        text: `${getUnitByDeviceType(DeviceTypes.HEAT)}`,
        rotate: 0,
        offsetX: 30,
        offsetY: -150,
        style: {
          fontSize: '0.75rem',
          fontWeight: 400,
          cssClass: 'apexcharts-yaxis-title'
        }
      }
    },
    colors: ['#9C2F19', '#9C2F194D', '#DF8431', '#DF84314D', '#1A609B', '#FFDE2B', '#713C16', '#D1D4D8', '#000'],
    xaxis: {
      categories: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    },
    series: [
      {
        name: trans('dashboard.consumption.wwater'),
        data: interpolateData(filterValuesByYear(warmWaterEnergyUsage, dayjs().year()))
      },
      {
        name: trans('dashboard.consumption.wwater_last_year'),
        data: interpolateData(filterValuesByYear(warmWaterEnergyUsage, dayjs().subtract(1, 'year').format('YYYY')))
      },
      {
        name: trans('dashboard.consumption.heating'),
        data: interpolateData(filterValuesByYear(heatUsage, dayjs().year()))
      },
      {
        name: trans('dashboard.consumption.heating_last_year'),
        data: interpolateData(filterValuesByYear(heatUsage, dayjs().subtract(1, 'year').format('YYYY')))
      }
    ]
  }

  const chartOptions = useChart(chart)

  useEffect(() => {
    if (!heatingSettings.isCentralWarmWater) {
      chartRef?.current?.chart.toggleSeries(trans('dashboard.consumption.wwater'))
      chartRef?.current?.chart.toggleSeries(trans('dashboard.consumption.wwater_last_year'))
    }
  }, [])

  return (
    <>
      {Object.values(heatUsage)?.length > 0 || Object.values(warmWaterEnergyUsage)?.length > 0 ? (
        <Card {...other}>
          <CardHeader title={header} subheader={subheader} />

          <Box sx={{ mt: 2, mr: 3 }}>
            <Chart
              ref={chartRef}
              dir="ltr"
              type="area"
              series={chart.series}
              options={chartOptions}
              width="100%"
              height={364}
            />
          </Box>
        </Card>
      ) : (
        <EmptyItem message={trans('dashboard.consumption.energy_empty')} />
      )}
    </>
  )
}

DashboardYearConsumption.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string
}
