import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import UserDataForm from '@/sections/user/form/user-data-form'
import UserState from '@/constants/UserState'
import useSingletonAxios from '@/hooks/use-singleton-axios'
import { handleErrorResponse } from '@/utils/misc'

export default function UserEditModal({ user }) {
  const { closeModal } = useModalContext()
  const request = useSingletonAxios()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/users/${user.id}/edit`
    },
    defaultValues: {
      id: user.id,
      company: user.company,
      name: user.name,
      address: {
        street: user.address.street,
        streetNumber: user.address.streetNumber,
        postcode: user.address.postcode,
        city: user.address.city
      },
      phone: user.phone,
      email: user.email,
      isAccountActive: user.state === UserState.ACTIVE || user.state === UserState.INVITED
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('userDataEdit')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`user.edit`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <UserDataForm />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <CustomButton
            color={'error'}
            onClick={() => {
              request({
                method: 'DELETE',
                url: `/api/users/${user.id}`
              })
                .then(() => {
                  router.get('/')
                  ToastCtl.show(trans('general.delete_success'), 'success')
                })
                .catch((e) => handleErrorResponse(e))
              closeModal('userDataEdit')
            }}
          >
            {trans('button.delete')}
          </CustomButton>
          <Stack direction={'row'} spacing={2}>
            <CustomButton onClick={() => closeModal('userDataEdit')}>{trans('button.cancel')}</CustomButton>
            <LoadingButton
              loading={submitting}
              variant={'contained'}
              onClick={() => {
                handleSubmit()
              }}
            >
              {trans('button.done')}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  )
}

UserEditModal.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string
}
