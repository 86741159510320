import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import InertiaTextField from '@/components/form/inertia-textfield'
import { FormProvider } from '@/context/use-form-context'
import { router, usePage } from '@inertiajs/react'
import { useForm } from '@/hooks/use-form'
import useSubmit from '@/hooks/use-submit'
import { LoadingButton } from '@mui/lab'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import { floors } from '@/utils/misc'
import { ToastCtl } from '@/utils/toast-ctl'

export default function PropertyCompleteStep1() {
  const { property } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-1`
    },
    defaultValues: {
      apartments: property.apartments.map((apartment) => ({
        id: apartment.id,
        label: apartment.label,
        floor: apartment.floor
      }))
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/properties/${response.data.id}/complete/step-2`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '400px', mx: 'auto' }}>
          <CardHeader title={trans('initial_setup.naming_apartment.header')} />
          <Typography variant={'body2'} sx={{ mx: 3 }}>
            {trans('initial_setup.naming_apartment.description')}
          </Typography>

          {property.apartments.map((apartment, index) => (
            <Stack
              direction={{
                sm: 'row',
                xs: 'column'
              }}
              spacing={2}
              sx={{ mx: 3, mt: 2 }}
            >
              <InertiaTextField
                key={index}
                name={`apartments.${index}.label`}
                input={{
                  label: trans('initial_setup.naming_apartment.naming'),
                  required: true
                }}
              />
              <InertiaAutocomplete
                name={`apartments.${index}.floor`}
                input={{
                  label: trans('field.apartment.floor'),
                  required: true
                }}
                items={floors()}
                sx={{
                  width: { sm: '75%', xs: '100%' }
                }}
              />
            </Stack>
          ))}

          <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mx: 3, my: 3 }}>
            <LoadingButton
              variant={'outlined'}
              onClick={() => router.get(`/properties/${property.id}/complete/step-0`)}
            >
              {trans('button.back')}
            </LoadingButton>
            <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
              {trans('button.next')}
            </LoadingButton>
          </Stack>
        </Card>
      </form>
    </FormProvider>
  )
}
