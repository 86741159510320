import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { FormProvider } from '@/context/use-form-context'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import { CheckOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'

export default function BillingTenantPrepayment({ apartmentTenant, editForbidden }) {
  const [saved, setSaved] = useState(false)

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/apartment_tenants/${apartmentTenant.id}`
    },
    defaultValues: {
      begin: apartmentTenant.begin,
      end: apartmentTenant.end,
      prepaymentGrossAmount: apartmentTenant.prepaymentGrossAmount
    }
  })

  useEffect(() => {
    if (saved) {
      setTimeout(() => setSaved(false), 2000)
    }
  }, [saved])

  const { submit, data, submitting, setOverrideFieldValues, setData } = methods

  const handleSubmit = (value) => {
    submit(
      (response) => {
        router.reload()
        setSaved(true)
      },
      null,
      {
        data: {
          begin: apartmentTenant.begin,
          end: apartmentTenant.end,
          prepaymentGrossAmount: value
        }
      }
    )
  }

  return (
    <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
      <form onSubmit={handleSubmit}>
        <InertiaNumericField
          name={'prepaymentGrossAmount'}
          input={{
            label: '',
            required: true,
            size: 'small',
            fixedDecimalScale: true,
            disabled: submitting || editForbidden,
            InputProps: {
              endAdornment: saved && <CheckOutlined sx={{ fontSize: '12px' }} />
            }
          }}
          callback={(value) => {
            if (value !== data.prepaymentGrossAmount) {
              handleSubmit(value)
            }
          }}
        />
      </form>
    </FormProvider>
  )
}
