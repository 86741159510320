import { trans } from '@/utils/translation'
import { Card, CardActions, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import InertiaTextField from '@/components/form/inertia-textfield'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import { FormProvider } from '@/context/use-form-context'
import Box from '@mui/material/Box'
import { useForm } from '@/hooks/use-form'
import { router, usePage } from '@inertiajs/react'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import { ToastCtl } from '@/utils/toast-ctl'
import HeatingSystems from '@/constants/HeatingSystems'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import dayjs from 'dayjs'

export default function AssignmentProcessStep1() {
  const { assignment } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/assignments/${assignment.id}/step-1`
    },
    defaultValues: {
      address: {
        street: assignment?.property?.address?.street ?? '',
        streetNumber: assignment?.property?.address?.streetNumber ?? '',
        postcode: assignment?.property?.address?.postcode ?? '',
        city: assignment?.property?.address?.city ?? ''
      },
      billingDate: assignment?.property?.billingDate ?? dayjs('31.12', 'DD.MM'),
      heatingSystem: assignment?.property?.heatingSettings?.heatingSystem ?? null,
      fuelCostType: assignment?.property?.heatingSettings?.fuelCostType?.id ?? null
    }
  })
  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/assignment/${response.data.id}/step-2`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
          <Card>
            <CardHeader title={trans('assignment.customize.property.title')} />
            <CardContent>
              <Stack direction={'column'} spacing={2}>
                <Grid container direction={'row'} spacing={2}>
                  <Grid item md={7} xs={12}>
                    <InertiaTextField
                      name={'address.street'}
                      input={{
                        label: trans('assignment.customize.property.street'),
                        required: true
                      }}
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <InertiaTextField
                      name={'address.streetNumber'}
                      input={{
                        label: trans('assignment.customize.property.street_number'),
                        required: true
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InertiaTextField
                      name={'address.postcode'}
                      input={{
                        label: trans('assignment.customize.property.postcode'),
                        required: true
                      }}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <InertiaTextField
                      name={'address.city'}
                      input={{
                        label: trans('assignment.customize.property.city'),
                        required: true
                      }}
                    />
                  </Grid>
                </Grid>

                <InertiaDatePicker
                  name={'billingDate'}
                  input={{
                    label: trans('assignment.customize.property.billing_date'),
                    required: true,
                    format: 'DD.MM',
                    views: ['day', 'month']
                  }}
                />
                <InertiaAutocomplete
                  name={'heatingSystem'}
                  input={{
                    label: trans('assignment.customize.property.heating_system'),
                    required: true
                  }}
                  items={Object.values(HeatingSystems)
                    .map((type) => ({
                      id: type,
                      label: trans(`heating_system.${type.toUpperCase()}`)
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                />
                <InertiaRemoteAutocomplete
                  name={'fuelCostType'}
                  input={{
                    label: trans('assignment.customize.property.fuel_cost_type'),
                    required: true
                  }}
                  url={'/api/fuel_cost_types?name=%s&itemsPerPage=50'}
                />
              </Stack>
            </CardContent>
            <CardActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: 3, pb: 2 }}>
              <LoadingButton variant={'outlined'} onClick={() => router.get(`/assignment/${assignment.id}/step-0`)}>
                {trans('button.back')}
              </LoadingButton>
              <LoadingButton loading={submitting} variant={'contained'} onClick={handleSubmit}>
                {trans('button.next')}
              </LoadingButton>
            </CardActions>
          </Card>
        </Box>
      </form>
    </FormProvider>
  )
}
