import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { FormProvider } from '@/context/use-form-context'
import { router } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import InertiaTextfield from '@/components/form/inertia-textfield'
import DialogContent from '@mui/material/DialogContent'
import { LoadingButton } from '@mui/lab'
import CustomButton from '@/components/custom-button'
import { ToastCtl } from '@/utils/toast-ctl'
import InertiaFileUpload from '@/components/form/inertia-file-upload'
import PropTypes from 'prop-types'
import useSubmit from '@/hooks/use-submit'

export default function NewsCreateEditModal({ news, callback }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: news?.id ? 'PUT' : 'POST',
      location: `/api/news${news?.id ? `/${news.id}/edit` : ''}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    defaultValues: {
      news: news?.id || null,
      title: news?.title || '',
      header: news?.header || '',
      link: news?.link || '',
      image: new File([], news?.image?.originalFileName)
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      callback ? callback() : router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('newsCreateEdit')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(news?.id ? 'news.edit' : 'news.create')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2} sx={{ mb: 2 }}>
              <InertiaTextfield
                name="title"
                input={{
                  label: trans('news.field.title')
                }}
              />
              <InertiaTextfield
                name="header"
                input={{
                  label: trans('news.field.header')
                }}
              />
              <InertiaTextfield
                name="link"
                input={{
                  label: trans('news.field.link')
                }}
              />
              <InertiaFileUpload
                name={'image'}
                input={{ label: trans('news.field.image'), accept: 'image/png,image/jpeg,image/gif' }}
                filename={news?.image?.originalFileName}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('newsCreateEdit')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}
NewsCreateEditModal.propTypes = {
  news: PropTypes.object
}
