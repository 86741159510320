import { trans } from '@/utils/translation'
import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { avatar, defaultTableOptions } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import { router, usePage } from '@inertiajs/react'
import Paper from '@mui/material/Paper'
import { formatCurrency, formatDate, formatPercent } from '@/utils/format'
import CustomButton from '@/components/custom-button'
import CreateModal from '@/sections/apartment-tenant/create-modal'
import { useModalContext } from '@/context/use-modal-context'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import axios from 'axios'
import { ToastCtl } from '@/utils/toast-ctl'
import BillingTenantPrepayment from '@/sections/billing/billing-tenant-prepayment'
import CollapseCard from '@/components/collapse-card'
import OverlayCard from '@/sections/billing/components/overlay-card'
import { useBoolean } from '@/hooks/use-boolean'
import { useState } from 'react'
import TableConfirmDelete from '@/components/table/table-confirm-delete'

export default function BillingTenantDataTable({ expanded, onExpand, editForbidden }) {
  const { billing } = usePage().props
  const { openModal } = useModalContext()

  const confirmRow = useBoolean()
  const [tenant, setTenant] = useState(null)

  const billingStart = new Date(billing.begin)
  const billingEnd = new Date(billing.end)
  const billingDays = (billingEnd - billingStart) / (1000 * 60 * 60 * 24)

  const completeness = (row) => {
    const tenantDays = row.original.tenants.reduce((acc, tenant) => {
      const tenantStart = tenant.begin < billing.begin ? new Date(billing.begin) : new Date(tenant.begin)
      const tenantEnd = tenant.end && tenant.end <= billing.end ? new Date(tenant.end) : new Date(billingEnd)
      const tenantDays = (tenantEnd - tenantStart) / (1000 * 60 * 60 * 24)
      return acc + tenantDays
    }, row.original.tenants.length)

    return billingDays > 0 ? tenantDays / billingDays : 0
  }

  const columns = [
    {
      id: 'number',
      header: trans('billing.detail.tenant_data.columns.number'),
      Cell: ({ row }) => row.index + 1,
      size: 50,
      enableSorting: false
    },
    {
      accessorKey: 'label',
      header: trans('billing.detail.tenant_data.columns.apartment'),
      size: 200,
      enableSorting: false
    },
    {
      id: 'tenants',
      header: trans('billing.detail.tenant_data.columns.change_tenant'),
      Cell: ({ row }) => (row.original.tenants.length > 0 ? row.original.tenants.length - 1 : 0),
      size: 100,
      enableSorting: false
    },
    {
      id: 'completeness',
      header: trans('billing.detail.tenant_data.columns.completeness'),
      Cell: ({ row }) => formatPercent(completeness(row), 0),
      size: 100,
      enableSorting: false
    },
    {
      accessorKey: 'id',
      header: trans('billing.detail.tenant_data.columns.prepayment'),
      Cell: ({ row }) =>
        formatCurrency(row.original.tenants.reduce((acc, tenant) => acc + tenant.prepaymentGrossAmount, 0)),
      size: 120,
      enableSorting: false,
      muiTableBodyCellProps: {
        align: 'right',
        sx: { paddingRight: { md: '11%', sm: '0' } }
      }
    }
  ]

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: ({ table }) => <></>,
    muiTableBodyRowProps: ({ row, table }) => ({
      onClick: (e) => {
        if (e.target.nodeName === 'TD' && !e.target.closest('.Mui-TableBodyCell-DetailPanel')) {
          row.toggleExpanded(!row.getIsExpanded())
        }
      },
      sx: { cursor: 'pointer' }
    }),
    muiDetailPanelProps: ({ row }) => ({
      sx: {
        background: (theme) => theme.palette.grey[200]
      }
    }),
    renderDetailPanel: ({ row }) => {
      return (
        <Stack spacing={2} alignItems={'start'}>
          {row.original.tenants.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {row.original.tenants.map((tenant) => (
                    <TableRow key={tenant.id}>
                      <TableCell sx={{ width: '4%' }}></TableCell>
                      <TableCell sx={{ width: '9%' }}>
                        <Avatar sx={{ width: 25, height: 25 }} src={avatar(tenant?.user)} />
                      </TableCell>
                      <TableCell sx={{ width: '25%' }}>{tenant.user?.name ?? trans('apartment.no_tenant')}</TableCell>
                      <TableCell sx={{ width: { lg: '18%', md: '16%', sm: '14%', xs: '14%' } }}>
                        {formatDate(tenant.begin < billing.begin ? billing.begin : tenant.begin)}
                      </TableCell>
                      <TableCell sx={{ width: { lg: '18%', md: '16%', sm: '14%', xs: '14%' } }}>
                        {tenant.end
                          ? formatDate(tenant.end > billing.end ? billing.end : tenant.end)
                          : formatDate(billing.end)}
                      </TableCell>
                      <TableCell sx={{ width: { xl: '15%', lg: '25%', md: '23%', sm: '33%', xs: '33%' } }}>
                        <BillingTenantPrepayment apartmentTenant={tenant} editForbidden={editForbidden} />
                      </TableCell>
                      {!editForbidden && (
                        <TableCell sx={{ width: '5%' }}>
                          <Stack direction={'row'} spacing={1} sx={{ mr: 1 }}>
                            <IconButton
                              key={'editHeatingCost'}
                              onClick={() =>
                                openModal(
                                  'apartmentTenantCreate',
                                  <CreateModal
                                    defaultValues={{
                                      id: tenant.id,
                                      user: tenant.user?.id,
                                      begin: tenant.begin,
                                      end: tenant.end,
                                      isEmpty: !tenant.user?.id,
                                      property: billing.property?.id,
                                      apartments: [tenant.apartment.id]
                                    }}
                                  />
                                )
                              }
                            >
                              <Iconify icon="solar:pen-bold" />
                            </IconButton>
                            <IconButton
                              key={'delete'}
                              sx={{ color: 'error.main' }}
                              onClick={() => {
                                setTenant(tenant)
                                confirmRow.onTrue()
                              }}
                            >
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <CustomButton
            variant="contained"
            color="primary"
            disabled={editForbidden}
            onClick={() =>
              openModal(
                'apartmentTenantCreate',
                <CreateModal defaultValues={{ property: billing.property?.id, apartments: [row.original.id] }} />
              )
            }
          >
            + {trans('billing.detail.tenant_data.add')}{' '}
          </CustomButton>
        </Stack>
      )
    },
    columns,
    data: billing.apartments
  })

  return (
    <>
      <OverlayCard>
        <CollapseCard
          title={trans('billing.detail.tenant_data.title')}
          header={
            <Typography variant="body2">
              {`${formatPercent(table.getRowModel().flatRows.reduce((acc, row) => acc + completeness(row), 0) / table.getRowModel().flatRows.length, 0)} ${trans('billing.detail.tenant_data.complete')}`}
            </Typography>
          }
          expand={expanded}
          onExpand={onExpand}
        >
          <MaterialReactTable table={table} />
        </CollapseCard>
      </OverlayCard>

      <TableConfirmDelete url={`/api/apartment_tenants/${tenant?.id}`} confirmRows={confirmRow} setValue={setTenant} />
    </>
  )
}
