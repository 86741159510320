import { Card, Stack } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { formatDate } from '@/utils/format'
import { alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import { useCallback, useRef, useState } from 'react'
import { trans } from '@/utils/translation'
import { LoadingButton } from '@mui/lab'

const messages = [
  {
    id: '1',
    user: {
      name: 'Ekaterina Tankova',
      avatarUrl: '@images/animals/avatar_2.jpg'
    },
    createdAt: '12.05.2023',
    message: 'Bitte beachten Sie die aktuellen Gesetzesänderungen.'
  },
  {
    id: '2',
    user: {
      name: 'Cao Yu',
      avatarUrl: '@images/animals/avatar_5.jpg'
    },
    createdAt: '12.05.2023',
    message: 'Wir denken daran. Vielen Dank.'
  }
]

export default function BillingDetailMessages() {

  const messageRef = useRef(null);

  const fileRef = useRef(null);

  const [message, setMessage] = useState('');

  const handleChangeMessage = useCallback((event) => {
    setMessage(event.target.value);
  }, []);

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleClickMessage = useCallback(() => {
    if (messageRef.current) {
      messageRef.current.focus();
    }
  }, []);

  const renderMessageList = (
    <Stack spacing={1.5} sx={{ px: 3, pb: 2 }}>
      {messages.map((message) => (
        <Stack key={message.id} direction="row" spacing={2}>
          <Avatar alt={message.user.name} src={message.user.avatarUrl} />

          <Paper
            sx={{
              p: 1.5,
              flexGrow: 1,
              bgcolor: 'background.neutral',
            }}
          >
            <Stack
              sx={{ mb: 0.5 }}
              alignItems={{ sm: 'center' }}
              justifyContent="space-between"
              direction={{ xs: 'column', sm: 'row' }}
            >
              <Box sx={{ typography: 'subtitle2' }}>{message.user.name}</Box>

              <Box sx={{ typography: 'caption', color: 'text.disabled' }}>
                {formatDate(message.createdAt)}
              </Box>
            </Stack>

            <Box sx={{ typography: 'body2', color: 'text.secondary' }}>{message.message}</Box>
          </Paper>
        </Stack>
      ))}
    </Stack>
  );

  const renderInput = (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(0, 3, 0, 3),
      }}
    >
      <Avatar src={'@images/animals/avatar_2.jpg'}/>

      <InputBase
        fullWidth
        value={message}
        inputRef={messageRef}
        placeholder={trans('billing.detail.message.write')}
        onChange={handleChangeMessage}
        sx={{
          pl: 1.5,
          height: 40,
          borderRadius: 1,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
        }}
      />

      <input type="file" ref={fileRef} style={{ display: 'none' }} />
    </Stack>

  );

  return (
    <Card sx={{py: 2}}>

      {renderMessageList}

      {renderInput}

      <Stack direction={'row'} justifyContent={'flex-end'} sx={{px: 3, pb: 2}} spacing={2}>

        <LoadingButton variant={'outlined'} sx={{my: 2}} onClick={handleAttach}>
          {trans('button.upload_file')}
        </LoadingButton>

        <LoadingButton variant={'contained'} sx={{my: 2}}>
          {trans('button.send')}
        </LoadingButton>

      </Stack>

    </Card>
  )
}
