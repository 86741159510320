import { useMemo } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Card } from '@mui/material'
import { defaultTableOptions } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import { router, usePage } from '@inertiajs/react'
import Box from '@mui/material/Box'
import CustomLink from '@/components/custom-link'
import AppointmentStateLabel from '@/sections/appointment/appointment-state-label'
import { formatDate } from '@/utils/format'
import AppointmentTypes from '@/constants/AppointmentTypes'
import Divider from '@mui/material/Divider'

export const TYPE_OPTIONS = {
  [AppointmentTypes.INSPECTION]: trans('dashboard.appointment.type.INSPECTION'),
  [AppointmentTypes.INSTALLATION]: trans('dashboard.appointment.type.INSTALLATION')
}

export default function DashboardAppointmentTable() {
  const { appointments } = usePage().props

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          `${row.assignment?.property?.address?.street} ${row.assignment?.property?.address?.streetNumber}`,
        header: trans('dashboard.appointment.columns.street'),
        size: 200,
        id: 'street'
      },
      {
        accessorKey: 'type',
        header: trans('dashboard.appointment.columns.type'),
        Cell: ({ renderedCellValue }) => TYPE_OPTIONS[renderedCellValue],
        size: 200,
        id: 'type'
      },
      {
        accessorFn: (row) => formatDate(row.begin),
        header: trans('dashboard.appointment.columns.date'),
        size: 200,
        id: 'begin'
      },
      {
        accessorKey: 'state',
        header: trans('field.state'),
        Cell: ({ renderedCellValue }) => <AppointmentStateLabel state={renderedCellValue} />,
        size: 200,
        id: 'state'
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    enablePagination: false,
    enableSorting: false,
    rowCount: 5,
    renderTopToolbar: () => (
      <Box m={2}>
        <Typography variant="h6">{trans('dashboard.appointment.header')}</Typography>
      </Box>
    ),
    enableRowActions: true,
    renderBottomToolbar: () => (
      <>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box m={2}>
          <CustomLink sx={{ float: 'right', textDecoration: 'none', mb: 2 }} href={'/appointments'}>
            {trans('dashboard.appointment.footer')}
          </CustomLink>
        </Box>
      </>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => router.get(`/appointments/${row.original.id}/detail`),
      sx: { cursor: 'pointer' }
    }),
    columns,
    data: appointments
  })

  return (
    <>
      <Card sx={{ mb: 4 }}>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
