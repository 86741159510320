import { Card, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'

export default function CollapseCard({ title, children, header, expand = false, onExpand }) {
  return (
    <Card>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: 1, mr: 1, cursor: 'pointer' }}
        onClick={(e) => {
          if (e.target.nodeName !== 'BUTTON') {
            onExpand()
          }
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton>{expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
          <Typography variant="h5">{title}</Typography>
        </Stack>
        {header}
      </Stack>
      <Collapse in={expand}>{children}</Collapse>
    </Card>
  )
}
