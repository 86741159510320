import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'

export default function CustomLabelValue({ label, value }) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant={'body2'}>{label}</Typography>
      <Typography variant={'body2'}>{value}</Typography>
    </Stack>
  )
}
