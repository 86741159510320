import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { Stack } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import Iconify from '@/components/iconify'
import { router, usePage } from '@inertiajs/react'
import IconButton from '@mui/material/IconButton'
import { formatCurrency, formatDate } from '@/utils/format'
import { useModalContext } from '@/context/use-modal-context'
import BillingHeatingCostModal from '@/sections/billing/billing-heating-cost-modal'
import axios from 'axios'
import { ToastCtl } from '@/utils/toast-ctl'
import CollapseCard from '@/components/collapse-card'
import OverlayCard from '@/sections/billing/components/overlay-card'

export default function BillingHeatingCostTable({ expanded, onExpand, editForbidden }) {
  const { billing } = usePage().props
  const { openModal } = useModalContext()

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.heatingCostType?.label,
        header: trans('billing.detail.heating_cost.columns.cost_type'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => row.alternativeCost ?? '-',
        header: trans('billing.detail.heating_cost.columns.alternative_cost'),
        size: 150,
        enableSorting: false,
        enableEditing: true
      },
      {
        accessorFn: (row) => formatDate(row.date),
        header: trans('billing.detail.heating_cost.columns.date'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => trans(`billing.detail.heating_cost.modal.${row.heatingCostType.costGroup}`),
        header: trans('billing.detail.heating_cost.columns.levy_area'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => formatCurrency(row.grossAmount),
        header: trans('billing.detail.heating_cost.columns.brutto'),
        size: 100,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <></>,
    columns,
    data: billing.heatingCosts,
    enableRowActions: !editForbidden,
    renderRowActions: ({ row }) => (
      <Stack direction={{ md: 'row', sm: 'column' }} spacing={1} sx={{ mr: 1 }}>
        <IconButton
          key={'editHeatingCost'}
          onClick={() =>
            openModal(
              'billingHeatingCost',
              <BillingHeatingCostModal billingId={billing.id} billingHeatingCost={row.original} />
            )
          }
        >
          <Iconify icon="solar:pen-bold" />
        </IconButton>
        <IconButton
          key={'delete'}
          sx={{ color: 'error.main' }}
          onClick={() => {
            axios
              .delete(`/api/billing_heating_cost/${row.original.id}`)
              .then(() => {
                router.reload()
              })
              .catch((e) => ToastCtl.show(e.message, 'error'))
          }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Stack>
    )
  })

  return (
    <>
      <OverlayCard>
        <CollapseCard
          title={trans('billing.detail.heating_cost.title')}
          header={
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant="body2">
                {formatCurrency(billing.heatingCosts.reduce((acc, curr) => acc + curr.grossAmount, 0))}{' '}
              </Typography>
              <CustomButton
                variant="contained"
                color="primary"
                disabled={editForbidden}
                onClick={() => openModal('billingHeatingCost', <BillingHeatingCostModal billingId={billing.id} />)}
              >
                + {trans('billing.detail.heating_cost.add')}{' '}
              </CustomButton>
            </Stack>
          }
          expand={expanded}
          onExpand={onExpand}
        >
          <MaterialReactTable table={table} />
        </CollapseCard>
      </OverlayCard>
    </>
  )
}
