import { Stack, Typography } from '@mui/material'
import { FormProvider } from '@/context/use-form-context'
import InertiaTextfield from '@/components/form/inertia-textfield'
import { Head } from '@inertiajs/react'
import useSubmit from '@/hooks/use-submit'
import { trans } from '@/utils/translation'
import { LoadingButton } from '@mui/lab'
import { ToastCtl } from '@/utils/toast-ctl'
import { useForm } from '@/hooks/use-form'
import InertiaSwitch from '@/components/form/inertia-switch'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import RegisterTenantModal from '@/pages/auth/register-tenant-modal'
import Roles from '@/constants/Roles'
import { useModalContext } from '@/context/use-modal-context'
import RegisterLayout from '@/layouts/register'
import InertiaNumericToggle from '@/components/form/inertia-numeric-toggle'
import Box from '@mui/material/Box'
import { useState } from 'react'
import CustomButton from '@/components/custom-button'
import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import { MIN_APARTMENT_COUNT_USER } from '@/constants/ApartmentState'
import CustomVideo from '@/components/custom-video'
import poster from '@/../images/poster/Startbild.jpg'

const Register = () => {
  const { openModal } = useModalContext()
  const theme = useTheme()

  const [step, setStep] = useState(1)

  const methods = useForm({
    url: {
      method: 'POST',
      location: `/api/users/register`
    },
    defaultValues: {
      role: Roles.ROLE_PROPERTY_ADMIN,
      apartmentCount: MIN_APARTMENT_COUNT_USER,
      name: '',
      company: '',
      phone: '',
      email: ''
    }
  })

  const {
    data,
    submit,
    submitting,
    setOverrideFieldValues,
    setData,
    validate,
    setError,
    errors,
    clearErrors,
    onChange
  } = methods

  const handleSubmit = () => {
    if (data.role === Roles.ROLE_TENANT) {
      openModal('registerTenantHint', <RegisterTenantModal />)
      return
    }

    submit(() => {
      setStep(4)
      ToastCtl.show(trans('register.success_message'), 'success')
    })
  }

  const handleResend = () => {
    axios
      .post('/register/resend-email', {
        email: data.email
      })
      .then(() => {
        ToastCtl.show(trans('register.resend_success_message'), 'success')
      })
  }

  useSubmit(handleSubmit)

  const renderStep1 = (
    <>
      <Typography variant={'h6'}>{trans('register.apartment_count')}</Typography>
      <Stack justifyContent={'center'} alignItems={'center'} sx={{ mx: 'auto', my: 6 }}>
        <InertiaNumericToggle
          name={'apartmentCount'}
          input={{
            label: '',
            subCallback: () => {
              if (data.apartmentCount > MIN_APARTMENT_COUNT_USER) {
                setOverrideFieldValues({ apartmentCount: data.apartmentCount - 1 })
                setData('apartmentCount', data.apartmentCount - 1)
              }

              if (data.apartmentCount === MIN_APARTMENT_COUNT_USER) {
                ToastCtl.show(
                  trans('assignment.customize.apartment_count_hint', { count: MIN_APARTMENT_COUNT_USER }),
                  'warning'
                )
              }
            }
          }}
        />
      </Stack>
    </>
  )

  const renderStep2 = (
    <>
      <Typography variant={'h6'} sx={{ mb: 3 }}>
        {trans('register.offer_recipient')}
      </Typography>
      <Stack direction={'column'} spacing={2}>
        <InertiaAutocomplete
          name={'role'}
          input={{
            label: trans('field.role_alt')
          }}
          items={[
            {
              id: Roles.ROLE_TENANT,
              label: trans('general.tenant')
            },
            {
              id: Roles.ROLE_PROPERTY_OWNER,
              label: trans('general.owner')
            },
            {
              id: Roles.ROLE_PROPERTY_ADMIN,
              label: trans('general.manager')
            }
          ]}
          callback={(value) => {
            if (value === Roles.ROLE_TENANT) {
              openModal('registerTenantHint', <RegisterTenantModal />)
            }
          }}
        />
        <InertiaTextfield
          name={'name'}
          input={{
            label: trans('field.name'),
            disabled: data.role === Roles.ROLE_TENANT,
            required: true
          }}
        />
        <InertiaTextfield
          name={'phone'}
          input={{
            label: trans('field.phone'),
            disabled: data.role === Roles.ROLE_TENANT,
            required: true
          }}
        />
        <InertiaTextfield
          name={'company'}
          input={{
            label: trans('field.company'),
            disabled: data.role === Roles.ROLE_TENANT
          }}
        />
      </Stack>
    </>
  )

  const renderStep3 = (
    <>
      <Typography variant={'h6'} sx={{ mb: 3 }}>
        {trans('register.email_recipient')}
      </Typography>
      <Stack direction={'column'} spacing={2}>
        <InertiaTextfield
          name={'email'}
          input={{
            label: trans('field.email'),
            disabled: data.role === Roles.ROLE_TENANT
          }}
        />
        <InertiaSwitch
          name={'terms'}
          input={{
            label: <div dangerouslySetInnerHTML={{ __html: trans('field.confirm_privacy') }}></div>
          }}
        />
      </Stack>
    </>
  )

  const renderStep4 = (
    <>
      <Typography variant={'h6'} sx={{ mb: 3 }}>
        {trans('register.success')}{' '}
      </Typography>
      <Stack justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Stack sx={{ mt: 2, mb: 4 }} direction={'column'} alignItems={'center'}>
          <Box sx={{ borderRadius: 3, mb: 1, overflow: 'hidden' }}>
            <CustomVideo src={'01_Anfrage_Website.mp4'} poster={poster} />
          </Box>
          <Typography
            variant={'body2'}
            sx={{
              fontWeight: theme.typography.caption.fontWeight,
              mb: 2
            }}
          >
            {trans('register.open_offer')}
          </Typography>
          <CustomButton
            variant={'outlined'}
            sx={{
              fontWeight: theme.typography.caption.fontWeight
            }}
            onClick={handleResend}
          >
            {trans('register.resend')}
          </CustomButton>
        </Stack>
      </Stack>
    </>
  )

  const handleStepChange = async () => {
    if (step === 2) {
      try {
        await validate()
      } catch (e) {
        const nameError = e?.response?.data?.violations?.filter((item) => item.propertyPath === 'name')
        const phoneError = e?.response?.data?.violations?.filter((item) => item.propertyPath === 'phone')

        setError('name', nameError[0]?.message)
        setError('phone', phoneError[0]?.message)

        if (nameError?.length > 0 || phoneError?.length > 0) {
          return
        }
      }
    }

    setStep(step + 1)
  }

  return (
    <>
      <Head title={trans('login.title')} />
      <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
        <form onSubmit={handleSubmit}>
          <Stack direction={'column'} spacing={2}>
            <Stack direction={'column'} spacing={2} sx={{ width: '100%' }}>
              <Box sx={{ display: step === 1 ? 'initial' : 'none' }}>{renderStep1}</Box>
              <Box sx={{ display: step === 2 ? 'initial' : 'none' }}>{renderStep2}</Box>
              <Box sx={{ display: step === 3 ? 'initial' : 'none' }}>{renderStep3}</Box>
              <Box sx={{ display: step === 4 ? 'initial' : 'none' }}>{renderStep4}</Box>
            </Stack>

            {step !== 4 && (
              <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{ mt: 4 }}>
                {step !== 1 && (
                  <LoadingButton
                    disabled={data.role === Roles.ROLE_TENANT || submitting}
                    onClick={() => setStep(step - 1)}
                    variant={'outlined'}
                  >
                    {trans('button.back')}
                  </LoadingButton>
                )}
                {step !== 3 ? (
                  <LoadingButton
                    loading={submitting}
                    onClick={handleStepChange}
                    variant={'contained'}
                    sx={{ ml: 'auto' }}
                    disabled={data.role === Roles.ROLE_TENANT}
                  >
                    {trans('button.next')}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={submitting}
                    onClick={handleSubmit}
                    variant={'contained'}
                    color={'secondary'}
                    disabled={data.role === Roles.ROLE_TENANT}
                  >
                    {trans('button.register')}
                  </LoadingButton>
                )}
              </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}

Register.layout = (page) => <RegisterLayout>{page}</RegisterLayout>

export default Register
