import { trans } from '@/utils/translation'
import AssignmentStateLabel from '@/sections/assignment/assignment-state-label'
import AssignmentState from '@/constants/AssignmentState'
import { formatDate } from '@/utils/format'
import AssignmentStep from '@/constants/AssignmentStep'

export const COLUMNS = [
  {
    accessorFn: (row) => row.user?.name ?? '-',
    header: trans('general.user'),
    size: 150,
    id: 'name'
  },
  {
    accessorFn: (row) => row.property?.label ?? '-',
    header: trans('general.property'),
    size: 150,
    id: 'property'
  },
  {
    accessorFn: (row) => row.apartmentCount ?? '-',
    header: trans('property.object_data.basic_data.unit_number'),
    size: 100,
    id: 'apartmentCount'
  },
  {
    accessorFn: (row) => formatDate(row.createdAt, 'DD.MM.YYYY HH:mm'),
    header: trans('general.created_at'),
    size: 150,
    id: 'createdAt'
  },
  {
    accessorFn: (row) => formatDate(row.updatedAt, 'DD.MM.YYYY HH:mm'),
    header: trans('general.updated_at'),
    size: 100,
    id: 'updatedAt'
  },
  {
    accessorFn: (row) => {
      if (row.state === AssignmentState.OPEN && row.step === AssignmentStep.ONBOARDING_OPEN) {
        if (row.user.emailConfirmed) {
          return trans(`assignment.step.EMAIL_CONFIRMED`)
        }

        return trans(`assignment.step.EMAIL_NOT_CONFIRMED`)
      }

      return trans(`assignment.step.${row.step}`)
    },
    header: trans('general.step'),
    size: 100,
    enableSorting: false
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    Cell: ({ renderedCellValue }) => <AssignmentStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.user.ALL') },
  [AssignmentState.OPEN]: {
    color: 'primary',
    label: trans('state.assignment.OPEN')
  },
  [AssignmentState.DECLINED]: {
    color: 'error',
    label: trans('state.assignment.DECLINED')
  },
  [AssignmentState.SENT]: {
    color: 'success',
    label: trans('state.assignment.SENT')
  }
}
