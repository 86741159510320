import { DialogActions, DialogTitle, Grid, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import dayjs from 'dayjs'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import DeviceTypes from '@/constants/DeviceTypes'
import InertiaTextfield from '@/components/form/inertia-textfield'
import DeviceStorageTypes from '@/constants/DeviceStorageTypes'
import InertiaSwitch from '@/components/form/inertia-switch'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import InertiaFileUpload from '@/components/form/inertia-file-upload'
import PropTypes from 'prop-types'

export default function DeviceCreateEditModal({ device, redirectToDetail = false }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: device?.id ? 'PUT' : 'POST',
      location: device?.id ? `/api/devices/${device.id}/edit` : `/api/devices`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    defaultValues: {
      deviceType: device?.type,
      number: device?.number,
      begin: device?.begin ?? dayjs().startOf('year').endOf('day').toDate(),
      end: device?.end,
      factor: device?.factor ?? 1.0,
      manufacturer: device?.manufacturer,
      storageType: device?.storageType ?? DeviceStorageTypes.CONTINUOUS,
      reportDate: device?.reportDate ?? dayjs().endOf('year').toDate(),
      aesKey: device?.aesKey,
      isWireless: device?.isWireless,
      property: device?.property?.id,
      apartment: device?.apartment?.id,
      imageOverall: new File([], device?.deviceImages?.find((image) => image.type === 'OVERALL')?.originalFileName),
      imageDetail: new File([], device?.deviceImages?.find((image) => image.type === 'DETAIL')?.originalFileName),
      isAutoGateway: device?.isAutoGateway,
      gateways: device?.deviceGateways?.map((deviceGateway) => deviceGateway?.gateway?.identifier).join(','),
      measurementPoint: device?.measurementPoint
    }
  })

  const { data, setData, setOverrideFieldValues, submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      redirectToDetail ? router.get(`/devices/${response.data.id}/detail`) : router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('deviceCreate')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(device?.id ? 'device.manual_edit' : 'device.manual_create')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Grid container direction={'row'} spacing={2}>
                <Grid item md={6} xs={12}>
                  <InertiaAutocomplete
                    name={'deviceType'}
                    input={{
                      label: trans('field.device.type'),
                      required: true
                    }}
                    items={Object.values(DeviceTypes).map((type) => ({
                      label: trans(`device.type.${type}`),
                      id: type
                    }))}
                    callback={(type) => {
                      if (type === DeviceTypes.HCA) {
                        setOverrideFieldValues({
                          storageType: DeviceStorageTypes.ACCUMULATED_UNTIL_DATE,
                          reportDate: dayjs().endOf('year')
                        })
                        setData('storageType', DeviceStorageTypes.ACCUMULATED_UNTIL_DATE)
                        setData('reportDate', dayjs().endOf('year'))
                        return
                      }

                      setOverrideFieldValues({ storageType: DeviceStorageTypes.CONTINUOUS })
                      setData('storageType', DeviceStorageTypes.CONTINUOUS)
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InertiaNumericField
                    name={'number'}
                    input={{
                      label: trans('field.device.number'),
                      required: true,
                      suffix: '',
                      decimalScale: 0,
                      thousandSeparator: ''
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InertiaDatePicker
                    name={'begin'}
                    input={{
                      label: trans('field.device.begin'),
                      required: true
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InertiaDatePicker
                    name={'end'}
                    input={{
                      label: trans('field.device.end'),
                      required: false
                    }}
                  />
                </Grid>
                {data.deviceType === DeviceTypes.HCA && (
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'factor'}
                      input={{
                        label: trans('field.device.factor'),
                        required: true,
                        suffix: '',
                        fixedDecimalScale: true,
                        decimalScale: 3
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <InertiaTextfield
                    name={'manufacturer'}
                    input={{
                      label: trans('field.device.manufacturer')
                    }}
                  />
                </Grid>
                {data.deviceType === DeviceTypes.HCA && (
                  <Grid item md={6} xs={12}>
                    <InertiaAutocomplete
                      name={'storageType'}
                      input={{
                        label: trans('field.device.storage_type'),
                        required: true
                      }}
                      items={Object.values(DeviceStorageTypes).map((type) => ({
                        label: trans(`device.storage_type.${type}`),
                        id: type
                      }))}
                    />
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <InertiaDatePicker
                    name={'reportDate'}
                    input={{
                      label: trans('field.device.report_date'),
                      required: true,
                      format: 'DD.MM',
                      views: ['day', 'month']
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InertiaSwitch
                    name={'isWireless'}
                    input={{
                      label: trans('field.device.wireless')
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InertiaTextfield
                    name={'aesKey'}
                    input={{
                      label: trans('field.device.aes_key')
                    }}
                  />
                </Grid>
                {data.isWireless && (
                  <>
                    <Grid item md={6} xs={12}>
                      <InertiaSwitch
                        name={'isAutoGateway'}
                        input={{
                          label: trans('field.device.auto_gateway')
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {!data.isAutoGateway && (
                        <InertiaTextfield
                          name={'gateways'}
                          input={{
                            label: trans('field.device.gateway')
                          }}
                        />
                      )}
                    </Grid>
                  </>
                )}

                <Grid item md={6} xs={12}>
                  <InertiaFileUpload
                    name={'imageDetail'}
                    input={{ label: 'Detailfoto', accept: 'image/png,image/jpeg,image/gif' }}
                    filename={device?.deviceImages?.find((image) => image.type === 'DETAIL')?.originalFileName}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InertiaFileUpload
                    name={'imageOverall'}
                    input={{ label: 'Gesamtfoto', accept: 'image/png,image/jpeg,image/gif' }}
                    filename={device?.deviceImages?.find((image) => image.type === 'OVERALL')?.originalFileName}
                  />
                </Grid>
              </Grid>
              <InertiaRemoteAutocomplete
                name={'property'}
                input={{
                  label: trans('general.property'),
                  required: true
                }}
                url={'/api/properties?label=%s&itemsPerPage=10'}
                defaultValue={{
                  id: device?.property?.id,
                  label: device?.property?.label
                }}
                callback={(propertyId) => {
                  if (propertyId !== data.property) {
                    setOverrideFieldValues({ apartment: null })
                    setData('apartment', null)
                  }
                }}
              />
              {data.property && (
                <InertiaRemoteAutocomplete
                  name={'apartment'}
                  input={{
                    label: trans('general.apartment')
                  }}
                  url={`/api/apartments?property.id=${data.property}&label=%s&itemsPerPage=10`}
                  defaultValue={{
                    id: device?.apartment?.id,
                    label: device?.apartment?.label
                  }}
                />
              )}
              <InertiaTextfield
                name={'measurementPoint'}
                input={{
                  label: trans('field.device.measurement_point')
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('deviceCreate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

DeviceCreateEditModal.propTypes = {
  device: PropTypes.object
}
