import { useCallback, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import MenuItem from '@mui/material/MenuItem'
import ButtonBase from '@mui/material/ButtonBase'
import CardHeader from '@mui/material/CardHeader'
import CustomPopover, { usePopover } from '@/components/custom-popover'
import Chart, { useChart } from '@/components/chart'
import Iconify from '@/components/iconify'
import PropTypes from 'prop-types'
import { formatNumber, getDecimalByDeviceType, getUnitByDeviceType } from '@/utils/format'
import DeviceTypes from '@/constants/DeviceTypes'
import dayjs from 'dayjs'
import { trans } from '@/utils/translation'
import { disableSeries, filterValuesByYear } from '@/utils/chart'
import EmptyItem from '@/sections/dashboard-overview/components/empty-item'

export default function DashboardWaterConsumption({ title, subheader, coldWaterUsage, warmWaterUsage, ...other }) {
  const chart = {
    legend: {
      position: 'top',
      horizontalAlign: 'right'
    },
    colors: ['#1A609B', '#9C2F19'],
    grid: {
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    tooltip: {
      y: {
        formatter: (value, config) => {
          if (!value) {
            return 0
          }

          return `${value.toLocaleString('de-DE', { maximumFractionDigits: getDecimalByDeviceType(DeviceTypes.WARM_WATER) })} ${getUnitByDeviceType(DeviceTypes.WARM_WATER)}`
        }
      }
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        offsetX: -20,
        formatter: (value) => {
          if (!value) {
            return 0
          }

          return `${formatNumber(value, getDecimalByDeviceType(DeviceTypes.WARM_WATER))}`
        }
      },
      title: {
        text: `${getUnitByDeviceType(DeviceTypes.WARM_WATER)}`,
        rotate: 0,
        offsetX: 44,
        offsetY: -100,
        style: {
          fontSize: '0.75rem',
          fontWeight: 400,
          cssClass: 'apexcharts-yaxis-title'
        }
      }
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    },
    series: [
      {
        year: dayjs().year(),
        data: [
          {
            name: trans('dashboard.water_consumption.cold_water'),
            data: filterValuesByYear(coldWaterUsage, dayjs().year()).map((item) => (item === 0 ? null : item))
          },
          {
            name: trans('dashboard.water_consumption.wwater'),
            data: filterValuesByYear(warmWaterUsage, dayjs().year()).map((item) => (item === 0 ? null : item))
          }
        ]
      },
      {
        year: dayjs().year() - 1,
        data: [
          {
            name: trans('dashboard.water_consumption.cold_water'),
            data: filterValuesByYear(coldWaterUsage, dayjs().subtract(1, 'year').format('YYYY')).map((item) =>
              item === 0 ? null : item
            )
          },
          {
            name: trans('dashboard.water_consumption.wwater'),
            data: filterValuesByYear(warmWaterUsage, dayjs().subtract(1, 'year').format('YYYY')).map((item) =>
              item === 0 ? null : item
            )
          }
        ]
      }
    ]
  }

  const chartRef = useRef()
  const popover = usePopover()
  const [seriesData, setSeriesData] = useState(dayjs().year())
  const chartOptions = useChart(chart)

  useEffect(() => {
    if (!Object.values(warmWaterUsage)?.length) {
      disableSeries(chartRef, trans('dashboard.water_consumption.wwater'))
    }

    if (!Object.values(coldWaterUsage)?.length) {
      disableSeries(chartRef, trans('dashboard.water_consumption.cold_water'))
    }
  }, [seriesData])

  const handleChangeSeries = useCallback(
    (newValue) => {
      popover.onClose()
      setSeriesData(newValue)
    },
    [popover]
  )

  return (
    <>
      {Object.values(warmWaterUsage)?.length > 0 || Object.values(coldWaterUsage)?.length > 0 ? (
        <>
          <Card {...other}>
            <CardHeader
              title={title}
              subheader={subheader}
              action={
                <ButtonBase
                  onClick={popover.onOpen}
                  sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    bgcolor: 'background.neutral'
                  }}
                >
                  {seriesData}

                  <Iconify
                    width={16}
                    icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                    sx={{ ml: 0.5 }}
                  />
                </ButtonBase>
              }
            />

            {chart.series.map((item) => (
              <Box key={item.year} sx={{ mt: 2, mr: 3, ml: 2 }}>
                {item.year === seriesData && (
                  <Chart
                    ref={chartRef}
                    dir="ltr"
                    type="bar"
                    series={item.data}
                    options={chartOptions}
                    width="100%"
                    height={264}
                  />
                )}
              </Box>
            ))}
          </Card>

          <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
            {chart.series.map((option) => (
              <MenuItem
                key={option.year}
                selected={option.year === seriesData}
                onClick={() => handleChangeSeries(option.year)}
                disabled={
                  parseInt(option.year) === parseInt(chart.series[1].year) &&
                  chart.series[1].data[0].data.every((item) => item === null) &&
                  chart.series[1].data[1].data.every((item) => item === null)
                }
              >
                {option.year}
              </MenuItem>
            ))}
          </CustomPopover>
        </>
      ) : (
        <EmptyItem message={trans('dashboard.consumption.water_empty')} />
      )}
    </>
  )
}

DashboardWaterConsumption.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string
}
