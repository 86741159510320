import { Grid, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import InfoView from '@/components/info-view'
import TaskHeader from '@/sections/task/task-header'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import WidgetSummary from '@/sections/dashboard/overview/widget-summary'
import DashboardAppointmentTable from '@/sections/dashboard/dashboard-appointment-table'
import DashboardDocumentTable from '@/sections/dashboard/dashboard-document-table'
import BillableProperties from '@/sections/dashboard/overview/billable-properties'
import DashboardInfo from '@/sections/dashboard/dashboard-info'
import { formatNumber } from '@/utils/format'
import VideoModal from '@/components/modal/video-modal'
import { useModalContext } from '@/context/use-modal-context'

export default function DashboardView() {
  const { auth, tasks, users, deviceValues, billings, news } = usePage().props
  const { openModal } = useModalContext()

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <InfoView
          header={
            <Stack>
              <Box>
                {trans('dashboard.info.header')} {auth.user.name}
              </Box>
            </Stack>
          }
          description={trans('dashboard.info.description')}
          tip={trans('dashboard.info.tip')}
          button={trans('dashboard.info.button')}
          onClick={() =>
            openModal('videoModal', <VideoModal src={'04_Dashboard.mp4'} controls={true} autoPlay={true} />, {
              maxWidth: 'lg'
            })
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DashboardInfo
          list={news.map((item) => ({
            id: item.id,
            coverUrl: item.image?.imageUrl
              ? item.image?.imageUrl
              : require(`../../../../images/Cover/watercolour-blue-violet.jpg`),
            header: item.header ? item.header : trans('dashboard.dashboard_info.header'),
            title: item.title,
            link: item.link
          }))}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <TaskHeader count={tasks} showDetail={tasks > 0} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <WidgetSummary
          title={trans('dashboard.active_users')}
          percent={users.total > 0 ? users.latest / users.total : 0}
          total={formatNumber(users.total, 0)}
          chart={{ series: [5, 18, 12, 51, 68, 11, 39, 37, 27, 20] }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <WidgetSummary
          title={trans('dashboard.readout')}
          percent={deviceValues.total > 0 ? deviceValues.latest / deviceValues.total : 0}
          total={formatNumber(deviceValues.total, 0)}
          chart={{ series: [5, 18, 12, 51, 68, 11, 39, 37, 27, 20] }}
        />
      </Grid>

      <Grid item xs={12} lg={7} xl={8}>
        <DashboardAppointmentTable />

        <DashboardDocumentTable />
      </Grid>

      <Grid item xs={12} lg={5} xl={4}>
        {auth.user.contractedPropertiesCount > 0 && (
          <BillableProperties
            chart={{
              series: billings.map((billing) => ({
                year: billing.year,
                data: [
                  { label: 'billable', value: billing.total },
                  { label: 'non-billable', value: billing.open }
                ]
              }))
            }}
            title={trans('dashboard.property.title')}
            footer={trans('dashboard.property.footer')}
          />
        )}
        {/*<DashboardInviteFriends
          price={trans('dashboard.invitation.price')}
          title={`${trans('dashboard.invitation.title1')} \n ${trans('dashboard.invitation.title2')}`}
          description={trans('dashboard.invitation.description')}
        />*/}
      </Grid>
    </Grid>
  )
}
