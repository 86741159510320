import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import LogListTable from '@/sections/log/log-list-table'

export default function LogListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('log.header')}
      </Typography>
      <LogListTable {...props} />
    </>
  )
}
