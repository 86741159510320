import { Grid, Stack } from '@mui/material'
import PropertyHeatingSettings from './property-heating-settings'
import PropertyConsumptionInfo from './property-consumption-info'
import PropertyAdminFunction from './property-admin-function'
import PropertyBillingSetting from './property-billing-setting'
import PropertyCo2Tax from './property-co2-tax'
import PropertyAnalysisEstimation from './property-analysis-estimation'
import PropertyData from './property-data'
import PropertyAddressData from './property-address-data'
import PropertyOwnerListTable from '@/sections/property/period/property-owner-list-table'
import PropertyContractorListTable from '@/sections/property/period/property-contractor-list-table'
import { usePage } from '@inertiajs/react'
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'
import PropertyManagerListTable from '@/sections/property/period/property-manager-list-table'

const PropertyDetailPage = () => {
  const { property, auth } = usePage().props

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <PropertyData />
            <PropertyAddressData />
            <PropertyConsumptionInfo />
            {hasRole(auth.user, Roles.ROLE_ADMIN) && <PropertyAdminFunction />}
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Stack direction={{ md: 'column' }} gap={3}>
            {hasRole(auth.user, Roles.ROLE_ADMIN) && <PropertyManagerListTable />}
            {hasRole(auth.user, Roles.ROLE_PROPERTY_ADMIN) && !property.isWEG && <PropertyOwnerListTable />}
            {hasRole(auth.user, Roles.ROLE_ADMIN) && <PropertyContractorListTable />}
            <PropertyBillingSetting />
            <Stack direction={{ xl: 'row' }} spacing={{ md: 3, xs: 3 }}>
              <Grid item xs={12} xl={6} lg={12}>
                <PropertyHeatingSettings />
              </Grid>
              <Grid item lg={12} xl={6}>
                <Stack direction={'column'} spacing={3}>
                  <PropertyCo2Tax />
                  <PropertyAnalysisEstimation />
                </Stack>
              </Grid>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default PropertyDetailPage
