import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaSwitch from '@/components/form/inertia-switch'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import HeatingSystems from '@/constants/HeatingSystems'

export default function PropertyEditHeatingSettingsModal({ property }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/edit-heating-settings`
    },
    defaultValues: {
      heatingSystem: property?.heatingSettings?.heatingSystem,
      begin: property?.heatingSettings?.begin,
      end: property?.heatingSettings?.end,
      constructionYear: property?.heatingSettings?.constructionYear,
      fuelCostType: property?.heatingSettings?.fuelCostType.id,
      efficiency: property?.heatingSettings?.efficiency,
      isCogenerationPlant: property?.heatingSettings?.isCogenerationPlant,
      cogenerationPlantCredit: property?.heatingSettings?.cogenerationPlantCredit,
      isCentralWarmWater: property?.heatingSettings?.isCentralWarmWater,
      warmWaterTemperature: property?.heatingSettings?.warmWaterTemperature,
      supplyTemperature: property?.heatingSettings?.supplyTemperature,
      returnTemperature: property?.heatingSettings.returnTemperature,
      roomTemperature: property?.heatingSettings?.roomTemperature
    }
  })

  const { data, submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('propertyEditHeatingSettings')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('property.heating.edit')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item md={6} xs={12}>
                <InertiaAutocomplete
                  name={'heatingSystem'}
                  input={{
                    label: trans('property.heating.basic_data.type'),
                    required: true
                  }}
                  items={Object.values(HeatingSystems).map((type) => ({
                    id: type,
                    label: trans(`heating_system.${type.toUpperCase()}`)
                  }))}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'efficiency'}
                  input={{
                    label: trans('property.heating.basic_data.efficiency'),
                    required: true,
                    suffix: ' %',
                    decimalScale: 0
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaDatePicker
                  name={'begin'}
                  input={{
                    label: trans('property.heating.basic_data.install_date'),
                    required: true,
                    format: 'MM.YYYY',
                    views: ['month', 'year']
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaDatePicker
                  name={'end'}
                  input={{
                    label: trans('property.heating.basic_data.removal_date'),
                    format: 'MM.YYYY',
                    views: ['month', 'year']
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaDatePicker
                  name={'constructionYear'}
                  input={{
                    label: trans('property.heating.basic_data.construction_year'),
                    views: ['year']
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaRemoteAutocomplete
                  name={'fuelCostType'}
                  input={{
                    label: trans('property.heating.basic_data.fuel'),
                    required: true
                  }}
                  url={'/api/fuel_cost_types?name=%s&itemsPerPage=50'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaSwitch
                  name={'isCogenerationPlant'}
                  input={{
                    label: trans('property.heating.basic_data.is_bhkw')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {data.isCogenerationPlant && (
                  <InertiaNumericField
                    name={'cogenerationPlantCredit'}
                    input={{
                      label: trans('property.heating.basic_data.bhkw_power'),
                      suffix: ' %',
                      decimalScale: 0,
                      required: true
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <InertiaSwitch
                  name={'isCentralWarmWater'}
                  input={{
                    label: trans('property.heating.basic_data.central_treatment')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'warmWaterTemperature'}
                  input={{
                    label: trans('property.heating.temperature.warm_water'),
                    suffix: ' °C',
                    decimalScale: 0
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'supplyTemperature'}
                  input={{
                    label: trans('property.heating.temperature.supply'),
                    suffix: ' °C',
                    decimalScale: 0
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'returnTemperature'}
                  input={{
                    label: trans('property.heating.temperature.return'),
                    suffix: ' °C',
                    decimalScale: 0
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'roomTemperature'}
                  input={{
                    label: trans('property.heating.temperature.room'),
                    suffix: ' °C',
                    decimalScale: 0
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('propertyEditHeatingSettings')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

PropertyEditHeatingSettingsModal.propTypes = {
  property: PropTypes.object
}
